import { motion } from 'framer-motion'
import React from 'react'
import { getTag, profileColor } from '../../utils/tools';

const Typing = () => {
    const ballTransition = {
        y: {
            yoyo: Infinity,
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 0.4,
            ease: 'easeOut',
        }
    };
    const animate = { y: ['50%', '-50%'] };
    return (
        <div className='flex gap-[2px] items-center py-3 px-3 text-white rounded-xl rounded-tl-none bg-gray-200 dark:bg-gray-800'>
            <motion.span
                className='typing_ball'
                transition={{...ballTransition, delay: 0.1}}
                animate={animate}
            />
            <motion.span
                className='typing_ball'
                transition={{...ballTransition, delay: 0.25}}
                animate={animate}
            />
            <motion.span
                className='typing_ball'
                transition={{...ballTransition, delay: 0.4}}
                animate={animate}
            />
        </div>
    )
}

export const TypingMini = () => {
    const ballTransition = {
        y: {
            yoyo: Infinity,
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 0.4,
            ease: 'easeOut',
        }
    };
    const animate = { y: ['50%', '-50%'] };
    return (
        <div className='flex gap-[1px] items-center py-1 px-1 text-white rounded-xl rounded-tl-none bg-transparent'>
            <motion.span
                className='typing_ball w-2 h-2'
                transition={{...ballTransition, delay: 0.1}}
                animate={animate}
            />
            <motion.span
                className='typing_ball w-2 h-2'
                transition={{...ballTransition, delay: 0.25}}
                animate={animate}
            />
            <motion.span
                className='typing_ball w-2 h-2'
                transition={{...ballTransition, delay: 0.4}}
                animate={animate}
            />
        </div>
    )
}

export const UserIsTyping = ({someone,chatitem}) => {
    //true if for chat item, false for chat 
    if (chatitem){
        return (
        
            <div className="flex items-center self-start gap-2 message-item mr-auto my-1" >
                <div>
                    <Typing />
                </div>
            </div>
        )
    }
    else {
        return (
        
            <div className="flex items-center self-start gap-2 message-item mr-auto my-1" >
                <div className={`relative flex-shrink-0 w-10 h-10 ${profileColor(getTag(someone))} rounded-full flex items-center justify-center`}>
                    <span className="font-bold text-base uppercase">
                        {getTag(someone)}
                    </span>
                </div>
                <div>
                    <Typing />
                </div>
            </div>
        )
    }
   
}

export default Typing