import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { ContextMenuChatItem } from '../MessageItem'
import { emojifyText, getTag, maximizeDisplay, profileColor } from '../../utils/tools';
import { SOCKET_SERVER_URL, USER_PIC_DEFAULT, readMessage } from '../../utils/func';
import moment from 'moment';
import Typing, { TypingMini, UserIsTyping } from './Typing';
import Seen from './Seen';
import io from 'socket.io-client';


const ChatItem = ({ data, isActive, onClose, activeUser, onEditGroupChat }) => {
    const initialContextValue = {
        state: false,
        x: 0,
        y: 0
    };


    const [showContext, setShowContext] = useState(initialContextValue);
    const [tag, setTag] = useState('');
    const [user, setUser] = useState(null);
    const [lastMessage, setLastMessage] = useState(data.latestMessage);
    const [latestMessageContent, setLatestMessageContent] = useState('');
    const [lastFile, setLastFile] = useState(data.latestFile);

    const [lastSender, setLastSender] = useState('');
    const handleContextMenu = (e) => {
        e.preventDefault();
        const { pageY, pageX } = e;
        setShowContext({
            state: true,
            x: pageX, y: pageY
        })
    }

    const handleCloseContextMenu = () => {
        setShowContext(initialContextValue)
    }

    const handleHideChat = () => {

    }

    const handleMarkAsUnreadChat = () => {

    }

    const handleLeaveChat = () => {

    }

    const handleCloseConversation = () => {
        onClose();
    }
    //Alert for a new mesage variable
    const [newMessage, setNewMessage] = useState(false);
    //Is typing event 
    const [isTyping, setIsTyping] = useState(false)
    const [someone, setSomeone] = useState("");
    const [socket, setSocket] = useState(null);
    useEffect(() => {
        //Initialize socket
        const socketConnection = io(SOCKET_SERVER_URL);

        //Receiving a new message

        // Add event listeners and handle socket events here

        setSocket(socketConnection);
        // Clean up the socket connection when the component unmounts

    }, []);

    useEffect(() => {
        
        if (data) {
            const socketConnection = io(SOCKET_SERVER_URL);

            socketConnection.on('connect', () => {
                console.log('Connected to server');
            });
            // Split space and get first char of splited to display
            if (!data.isGroupChat) {
                // get the id of the another user
                const anotherUser = data.users.find(user => user._id !== activeUser._id);
                if (anotherUser) {
                    setTag(getTag(anotherUser.name))
                    setUser(anotherUser);
                }
                if (data.latestMessage && data.latestMessage.sender)
                    setLastSender(data.latestMessage.sender._id === activeUser._id ? 'Vous:' : '')
            } else {
                // console.log('not ', data)
                setTag(getTag(data.chatName));
            }
            setLastMessage(data.latestMessage)
            setLastFile(data.latestFile)
            // console.log(data.latestMessage ? data.latestMessage : "nothing")
            setNewMessage(data.latestMessage ? (data.latestMessage.isSeen.find(item => item.user === activeUser._id) ? false : true) : false)

            // set last message content
            if (data.latestMessage) {
                setLastMessage(data.latestMessage);
                setLatestMessageContent(data.latestMessage.content)
            }
            // set latest message to filename
            if (data.latestFile) {
                if (data.latestMessage &&
                    (new Date(lastMessage?.createdAt) < new Date(data.latestFile?.createdAt))
                ) {
                    setLastMessage(data.latestFile);
                    setLatestMessageContent(data.latestFile.filename)
                }
            }


            //When message is read
            let idSocket = data.latestMessage ? (data.latestMessage.chat._id ? data.latestMessage.chat._id : data.latestMessage.chat) : data._id;
            socketConnection.on(`seen${idSocket}`, (val) => {
                const seenMessage = () => {
                    readMessage(activeUser.token, { "id": idSocket, user: activeUser._id }).then(res => {

                    })
                }

                if (val === activeUser._id) {
                    setNewMessage(false);
                    seenMessage();
                }
            })
            //On typing 
            socketConnection.on(`typing${idSocket}`, (sender) => {
                if (sender !== activeUser.name) {
                    setIsTyping(true);
                    setSomeone(sender);
                    setTimeout(() => {
                        setIsTyping(false);
                    }, 2000);
                }
            })
            return () => {
                socketConnection.disconnect();
            };
        }

    }, [data])

    const handleOpenEditGroupChat = () => {
        onEditGroupChat && onEditGroupChat(data);
    }

    return (
        <motion.div
            className={`relative p-3 rounded-lg flex items-center gap-2 justify-between ${isActive && 'bg-indigo-100 dark:bg-gray-700'} active:bg-indigo-200 dark:active:bg-gray-950 hover:bg-indigo-100 dark:hover:bg-gray-950 transition-all duration-200 cursor-pointer`}
            onContextMenu={handleContextMenu}
        >
            {/* Profile & last message */}
            <div className="flex items-center gap-2 w-full">
                {/* Profile */}
                <div className={`relative flex-shrink-0 w-12 h-12 max-w-12 max-h-12 ${!user ? profileColor(tag) : 'ring-1 ring-gray-300 dark:ring-gray-950'} rounded-full flex items-center justify-center`}>
                    {
                        !user ?
                        <span className="font-bold text-base uppercase">{tag}</span>
                        :
                        <img src={user.pic === USER_PIC_DEFAULT ? user.pic : `${SOCKET_SERVER_URL}/users/${user.pic}`} 
                            className='rounded-full user-profile'
                        />
                    }
                    {/* is not a group */}
                    {
                        data.isGroupChat ?
                        ((lastMessage) && <div className={`absolute flex items-center justify-center h-[23px] w-[23px] ${profileColor(getTag(lastMessage?.sender.name))} rounded-full -bottom-2 -right-0 ring-white dark:ring-gray-800`}>
                            {
                                lastMessage.sender.pic && lastMessage.sender.pic !== '' ?
                                <img src={lastMessage.sender.pic === USER_PIC_DEFAULT ? lastMessage.sender.pic : `${SOCKET_SERVER_URL}/users/${lastMessage.sender.pic}`}
                                    className='rounded-full'
                                />
                                :
                                <span className='font-bold text-xxs'>{getTag(lastMessage?.sender.name)}</span>
                            }
                        </div>)
                        :
                        <span className='absolute h-[14px] w-[14px] border-2 bg-green-500 border-white rounded-full -bottom-0 -right-1'></span>
                    }
                </div>

                <div className="flex flex-col justify-between gap-1 flex-grow">
                    <div className="flex justify-between w-full">
                        <h1 className='text-sm font-bold text-gray-600 dark:text-gray-300 line-clamp-1'>{data.isGroupChat ? data.chatName : user?.name}</h1>
                        {
                            lastMessage &&
                            <div className="flex flex-col text-right">
                                <span className="text-xxs text-gray-500 whitespace-nowrap">
                                    {moment(lastMessage.createdAt).format('L')} {moment(lastMessage.createdAt).format('LT')}
                                </span>
                            </div>
                        }
                    </div>

                    <div className="flex gap-1">
                        {
                            !isTyping ?
                            <>
                                {
                                    lastMessage &&
                                    <p className={`text-gray-700 dark:text-gray-300 text-sm line-clamp-1 chatbox-input ${newMessage && 'font-extrabold'}`}
                                        dangerouslySetInnerHTML={{ __html: maximizeDisplay(emojifyText(`${!data.isGroupChat ? lastSender : ''} ${latestMessageContent}` || ''), false, false) }}
                                    />
                                }
                                {
                                    newMessage && 
                                    <div className="right-2 flex items-center justify-center ml-auto">
                                        <span className='h-fit w-fit px-2 py-0 text-xxs text-white bg-rose-500 animate-pulse rounded-full'>nouveau</span>
                                    </div>
                                }
                            </> :
                            <TypingMini someone={someone} chatitem={true} />
                        }

                    </div>

                </div>
            </div>

            {showContext.state &&
                <ContextMenuChatItem
                    isGroup={data.isGroupChat}
                    closeContextMenu={handleCloseContextMenu}
                    x={showContext.x}
                    y={showContext.y}
                    isAdminGroup={data.isGroupChat && data.groupAdmin._id === activeUser._id}
                    onEditGroupChat={handleOpenEditGroupChat}
                    onHide={handleHideChat}
                    onLeave={handleLeaveChat}
                    onMarkAsUnread={handleMarkAsUnreadChat}
                    isActive={isActive}
                    onCloseConversation={handleCloseConversation}
                />
            }

        </motion.div>

    )
}

export default ChatItem