import {Routes, Route, Outlet} from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Messenger from './components/Messenger';
function App() {
  return (
    <>
      <Routes>
        <Route path='/' index element={<Login />} exact/>
        <Route path='signup' element={<Signup />} />
        <Route path='messenger'>
          <Route index={true} element={<Messenger />} />
          <Route path=':chatId' element={<Messenger />} />
        </Route>
      </Routes>
      <Outlet />
    </>
  );
}

export default App;
