import React, { useEffect, useState } from 'react'
import { AiOutlineUserAdd, AiOutlineUserDelete } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { PiMagnifyingGlass, PiShareFatLight } from 'react-icons/pi'
import ContactItem from './contact/ContactItem';
import { BsCheck, BsCheckLg } from 'react-icons/bs';
import { getChatData, getTag, profileColor } from '../utils/tools';
import { getChats, getUsers, session } from '../utils/func';

const Transfert = ({ data, onClose, onTransfert, activeUser, theme, chatId }) => {
    const [chats, setChats] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedChats, setSelectedChats] = useState([]);
    const [selectedContacts, setSelectedContacts] = useState([]);
    // Select people
    const handleAddContact = (contact) => {
        setSelectedContacts(prev => [...prev, contact]);
    }

    // Remove selected
    const handleRemoveContact = (contactId) => {
        setSelectedContacts(prev => prev.filter(Id => Id !== contactId));
    }
    
    // Select people
    const handleAddChat = (chat) => {
        setSelectedChats(prev => [...prev, chat]);
    }

    // Remove selected
    const handleRemoveChat = (chatId) => {
        setSelectedChats(prev => prev.filter(Id => Id !== chatId));
    }

    const handleTransfertMessage = () => {
        onTransfert && onTransfert({
            chats: selectedChats,
            contacts: selectedContacts,
            message: data
        });
    }

    const filterGroup = (data) => {
        const key = search.toLowerCase();
        return data.filter(item => {
            return item.chatName.toLowerCase().includes(key)
        });
    }
    
    const filterContact = (data) => {
        const key = search.toLowerCase();
        return data.filter(item => {
            return item.name.toLowerCase().includes(key)
        });
    }

    useEffect(() => {
        // Le chat no alaina
        getChats(activeUser.token)
        .then(res => {
            if (res.data)
                setChats(res.data.filter(chat => chat.isGroupChat && chat._id !== chatId))
        }).catch(err => console.log(err));

        getUsers(activeUser.token)
        .then(res => {
            if (res.data)
                setContacts(res.data)
        }).catch(err => console.log(err));
    }, [activeUser]);

    return (
        <div className={`${theme} w-full max-w-lg p-[0px] rounded-lg min-h-[500px] flex flex-col`}>
            <div className='flex flex-col flex-grow w-full bg-white dark:bg-slate-800 p-4 shadow-md rounded-lg'>
                <div className="flex items-center justify-between pb-2 border-b border-gray-300 dark:border-gray-700">
                    <h1 className='text-gray-700 dark:text-gray-200 flex gap-2'>
                        <PiShareFatLight className='w-6 h-6' />
                        Transferer ce message à:
                    </h1>
                    <button className='text-gray-500 dark:hover:text-white hover:text-black'
                        onClick={() => onClose && onClose()}
                    >
                        <MdClose className='w-6 h-6' />
                    </button>
                </div>
                <div className="flex-grow px-2 flex flex-col">
                    <div className="flex-grow flex flex-col my-2">
                        <label htmlFor="search" className='text-black dark:text-white text-xs py-1'>
                            <li className="list-disc">Séléctionner des contacts ou des groupes</li>
                        </label>
                        <div className="flex-grow p-2 rounded-lg border bg-gray-50 dark:bg-gray-900 border-gray-300 dark:border-gray-700">
                            {/* Group Message */}
                            <div className="w-full">
                                <div className="flex items-center p-2 gap-2">
                                    <PiMagnifyingGlass className='flex-shrink-0 text-gray-400'/>
                                    <input
                                        id='search'
                                        type="search"
                                        onChange={(e) => setSearch(e.currentTarget.value)}
                                        className='text-sm text-black dark:text-white flex-grow bg-transparent outline-none'
                                        placeholder='Chercher...'
                                        autoComplete='new-password'
                                    />
                                </div>
                                <div className="w-full" hidden={chats.length === 0}>
                                    <h2 className="font-bold text-xs my-2 text-black dark:text-white border-b border-gray-200 dark:border-gray-800 pb-1">Groupe de message</h2>
                                    <div className="flex flex-col overflow-y-auto h-fit max-h-[160px] py-1">
                                        {
                                            filterGroup(chats).map((chat, id) => (
                                                <div key={chat._id}
                                                    className='flex items-center gap-0 p-2 pr-3 hover:bg-indigo-200 dark:hover:bg-sky-950'
                                                >
                                                    <div className="flex gap-2 items-center">
                                                        <div className={`relative flex-shrink-0 w-10 h-10 ${profileColor(getTag(chat.chatName))} rounded-full flex items-center justify-center`}>
                                                            <span className="font-bold text-base uppercase">{getTag(chat.chatName)}</span>
                                                        </div>
                                                        <h1 className="text-bold text-black dark:text-white break-all">{chat.chatName}</h1>
                                                    </div>
                                                    {
                                                        selectedChats.indexOf(chat._id) === -1 ?
                                                        <button
                                                            className='ml-auto flex items-center gap-2 text-xs font-semibold border border-gray-300 dark:border-gray-700 bg-gray-200 dark:bg-gray-900 text-gray-200 dark:text-white p-2 rounded-lg'
                                                            onClick={() => handleAddChat(chat._id)}
                                                        >
                                                            <BsCheck  className='w-6 h-6 opacity-0'/>
                                                        </button> :
                                                        <button
                                                            className={`ml-auto flex items-center gap-2 text-xs font-semibold ${theme} p-2 rounded-lg text-white border border-transparent`}
                                                            onClick={() => handleRemoveChat(chat._id)}
                                                        >
                                                            <PiShareFatLight  className='w-6 h-6'/>
                                                        </button>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="w-full" hidden={contacts.length === 0}>
                                    <h2 className="font-bold text-xs my-2 text-black dark:text-white border-b border-gray-200 dark:border-gray-800 pb-1">Contacts</h2>
                                    <div className="flex flex-col overflow-y-auto h-fit max-h-[160px] py-1">
                                        {
                                            filterContact(contacts).map((contact, id) => (
                                                <div key={contact._id}
                                                    className='flex items-center gap-0 p-2 pr-3 hover:bg-indigo-200 dark:hover:bg-sky-950'
                                                >
                                                    <div className="flex gap-2 items-center">
                                                        <div className={`relative flex-shrink-0 w-10 h-10 ${profileColor(getTag(contact.name))} rounded-full flex items-center justify-center`}>
                                                            <span className="font-bold text-base uppercase">{getTag(contact.name)}</span>
                                                        </div>
                                                        <h1 className="text-bold text-black dark:text-white break-all">{contact.name}</h1>
                                                    </div>
                                                    {
                                                        selectedContacts.indexOf(contact._id) === -1 ?
                                                        <button
                                                            className='ml-auto flex items-center gap-2 text-xs font-semibold border border-gray-300 dark:border-gray-700 bg-gray-200 dark:bg-gray-900 text-gray-200 dark:text-white p-2 rounded-lg'
                                                            onClick={() => handleAddContact(contact._id)}
                                                        >
                                                            <BsCheck  className='w-6 h-6 opacity-0'/>
                                                        </button> :
                                                        <button
                                                            className={`ml-auto flex items-center gap-2 text-xs font-semibold ${theme} p-2 rounded-lg text-white border border-transparent`}
                                                            onClick={() => handleRemoveContact(contact._id)}
                                                        >
                                                            <PiShareFatLight  className='w-6 h-6'/>
                                                        </button>
                                                    }
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                {/*  */}
                            </div>
                        </div>
                        <div className="w-full flex mt-2">
                            <button
                                className={`p-3 px-4 text-white text-sm ${theme} rounded-md mx-auto disabled:cursor-not-allowed disabled:opacity-70`}
                                onClick={handleTransfertMessage}
                                disabled={selectedChats.length === 0 && selectedContacts.length === 0}
                            >
                                Transérer ce message
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Transfert