import axios from 'axios';

// const base_url = 'http://localhost:5000'
const base_url = 'https://messagerie-interne.solumada.mg'
export const SOCKET_SERVER_URL = base_url;
export const USER_PIC_DEFAULT = "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg";

export const getUsers = async (token, q = null, signal = undefined) => {
    const query = q ? `?search=${q}` : '';
    const res = await axios.get(`${base_url}/api/user${query}`, {
        headers: { Authorization: `Bearer ${token}`},
        signal: signal
    });
    return res;
}

export const searchContacts = async (token, search) => {
    return await axios.get(`${base_url}/api/user?search=${search}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });
}

export const getUser = async (userId) => {
    const res = await axios.post(`${base_url}/api/user/getuser`,{id:userId});
    return res;
}

export const getUserIn = async (arrayOfId) => {
    const idRequest = arrayOfId.map(id => `id=${id}`).join('&');
    const res = await axios.get(`${base_url}/users?${idRequest}`);
    return res;
}

// LOGIN
export const signIn = async (auth) => {
    // url:: api/user/login
    const res = await axios.post(`${base_url}/api/user/login`, {
        email: auth.username,
        password: auth.password
    });
    
    console.log("test", res);
    return res;
}

// CHAT
export const getChats = async (token) => {
    const res = await axios.get(`${base_url}/api/chat`, {
        headers: { Authorization: `Bearer ${token}`}
    });
    return res;
}

export const getChat = async (token, chatId) => {
    const res = await axios.get(`${base_url}/api/chat/${chatId}`,{
        headers: { Authorization: `Bearer ${token}`}
    });
    return res;
}


export const updateTheme = async (token, chatId, chatTheme) => {
    const res = await axios.put(
        `${base_url}/api/chat/theme`, {chatTheme, chatId}, {
            headers: {
                Authorization: `Bearer ${token}`
            },   
        }
    )
    return res;
}

export const createChat = async (token, data) => {
    const res = await axios.post(`${base_url}/api/chat/group`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return res;
}

export const accessChat = async (token, userId) => {
    const res = await axios.post(`${base_url}/api/chat`, {
        userId: userId
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return res;
}


// CONVERSATIONS
export const getConversations = async (token, chatId) => {
    const res = await axios.get(`${base_url}/api/message/${chatId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        },   
    });
    return res;
}

export const putConversation = async (token, convId, data) => {
    const res = await axios.put(
        `${base_url}/api/message`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            },   
        }
    )
    return res;
}

export const postConversation = async (data) => {
    const res = await axios.post(
        `${base_url}/conversations`, 
        data,
    )
    return res;
}

// export const putConversation = async (convId, data) => {
//     const res = await axios.put(
//         `${base_url}/message/${convId}`, 
//         data,
//     )
//     return res;
// }

export const deleteConversation = async (convId) => {
    const res = await axios.delete(
        `${base_url}/conversations/${convId}`
    )
    return res;
}

export const getLastConversation = async (chatId) => {
    const res = await axios.get(`${base_url}/conversations?chatId=${chatId}&_sort=createdAt&_order=desc&_limit=1`);
    return res;
}

export const signUp = async (auth) =>{
    const res = await axios.post(`${base_url}/api/user`, {
        name: auth.username,
        password: auth.password,
        email: auth.email
    });
    return res;
}



// send message
export const sendMessage = async (token, message) =>{
    const res = await axios.post(`${base_url}/api/message`, message, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return res;
}

export const updateMessage = async (token, data) => {
    const res = await axios.put(
        `${base_url}/api/message`, {...data, messageId: data._id}, {
            headers: {
                Authorization: `Bearer ${token}`
            },   
        }
    )
    return res;
}
// Mark message as read
export const readMessage = async (token, data) => {
    const res = await axios.post(`${base_url}/api/message/seen`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        },
    });
    return res;
}

export const deleteMessage = async (token, messageId) => {
    console.log(token)
    const res = await axios.delete(
        `${base_url}/api/message/${messageId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },   
        }
    )
    return res;
}

export const session = {
    user: () => {
        const local = localStorage.getItem('_user');
        return local ? JSON.parse(local) : null;
    },
    setUser: (user) => {
        if (!user) return;
        localStorage.setItem('_user', JSON.stringify(user));
    }
}

export const sendFile = async (token, data) => {
    const res = await axios.post(`${base_url}/api/message/sendFile`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        }
    })
    console.log("sendFile", data);
    return res;
}

export const addMemberToGroup = async (token, chatId, userId) => {
    const res = await axios.put(`${base_url}/api/chat/groupadd`, {chatId, userId}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return res;
}

export const removeMemberToGroup = async (token, chatId, userId) => {
    const res = await axios.put(`${base_url}/api/chat/groupremove`, {chatId, userId}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return res;
}

export const updateChatName = async (token, data) => {
    const res = await axios.put(`${base_url}/api/chat/rename`, {...data}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return res;
}

export const updateUser = async (token, data) => {
    console.log(token)
    const res = await axios.put(`${base_url}/api/user/update`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    })
    return res;
}


export const sortChatItems = (array) => {
    return array.sort((a, b) => {
        return  new Date(b.updatedAt) - new Date(a.updatedAt);
    });
};

export const downloadFile = async (token, filename) => {
    // console.log("filename", filename);
    var param = filename.filename
    const res = await axios.get(`${base_url}/api/message/download/${param}`, {
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: "blob"
    })
    return res;
}

export const getAllFile = async(token, id)=>{
    // console.log("getAllFilegetAllFile ", token, id);
    const file = await axios.get(`${base_url}/api/message/allFile/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    // console.log("file ==>", file);
    return file
}