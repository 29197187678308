import { useEffect, useRef, useState } from 'react';
import { MessagesData, STATUS, getTag, profileColor } from './../utils/tools';
import Chat from './chat/Chat';
import { BsBell } from 'react-icons/bs';
import { GiConversation } from 'react-icons/gi';
import { AiOutlineContacts, AiOutlineMessage } from 'react-icons/ai';
import { RiContactsBook2Line } from 'react-icons/ri';
import Conversations from './chat/Conversations';
import Contacts from './contact/Contacts';
import ThemSwitcher from './ThemSwitcher';
import { BiCog } from 'react-icons/bi';
import { useNavigate, useParams, } from 'react-router-dom';
import useSession from '../hooks/useSession';
import { SOCKET_SERVER_URL, accessChat, addMemberToGroup, getChat, removeMemberToGroup, sendMessage, session } from '../utils/func';
import UserActiveModal from './UserActiveModal';
import { motion } from 'framer-motion';
import ImageViewer from './ImageViewer';


function Messenger () {
  const [showModal, setShowModal] = useState(false);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [userTag, setUserTag] = useState('');
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState(session.user());
  const initialOpenChat = {
    state: false,
    chat: null
  }
  const [openChat, setOpenChat] = useState(initialOpenChat);
  const initialActiveTab = 'conv';
  const [activeTab, setactiveTab] = useState(initialActiveTab);

  const handleUserChange = (user) => {
    setUserTag(getTag(user.name));
    // update user
    setActiveUser(session.user());
  }
  
  const handleCloseConversation = () => {
    setTimeout(() => {
      setOpenChat(initialOpenChat);
      navigate('/messenger');
    }, 0);
  }
  
  const handleOpenConversation = (param) => {
    if (openChat.chat && openChat.chat._id === param.chat._id) return;
    // setOpenChat(initialOpenChat);
    setTimeout(() => {
      setOpenChat(param)
    }, 0)
  }

  // hooks to fetch message at first render
  useEffect(() => {
    if (activeUser) {
      setUserTag(getTag(activeUser.name));
    }
    
  }, [activeUser]);


  useEffect(() => {
    if (chatId && activeUser) {
      getChat(activeUser.token, chatId).then(res => {
        if (res.data) {
          setOpenChat({
            state: true,
            chat: res.data
          })
        } else {
          navigate('/messenger');
          setOpenChat(initialOpenChat);
        }
      }).catch(err => {
        console.log(err)
      })
    } else {
      setOpenChat(initialOpenChat);
    }
  }, [chatId])

  if (!session) return;

  const handleTransfert = (data) => {
    const { chats, contacts, message } = data;

    return new Promise((resolve, reject) => {
      try {
        const chatsId = chats;
        contacts.map(async contactId => {
          const chat = await accessChat(activeUser.token, contactId);
          if (chat.data) {
            console.log(chat.data)
            chatsId.push(chat.data._id);
          }
        }) 
        // Send message to chat
        chatsId.map(chatId => {
          sendMessage(activeUser.token, {
            chatId: chatId,
            content: message.content,
            isTransfert: true,
            transfert: message._id,
            isReply: false,
          }).then(res => {
            console.log(res.data)
            if (res.data) {
              console.log(res.data)
            }
          }).catch(err => {
            console.log(err)
          });
        });
        
        resolve(true);
      } catch (err) {
        reject(false);
        console.log(err)
      }
    });
  }


  const handleSayHi = (user) => {
    accessChat(activeUser.token, user._id)
    .then(res => {
      if (res.data) {
        // send "Hi" Message
        sendMessage(activeUser.token, {
          chatId: res.data._id,
          content: '[[:1f44b:]]',
          isReply: false
        }).then(resp => {
          if (resp.data) {
            // open chat
            setOpenChat({
              state: true,
              chat: ({...res.data, latestMessage: resp.data})
            });
          }
        });
        setTimeout(() => {
          setactiveTab('conv');
        }, 100);
        console.log('chat created', res.data)
      }
    }).catch(err => {
      console.log(err);
    })
  }
  
  const handleSendMessageTo = (userId) => {
    accessChat(activeUser.token, userId)
    .then(res => {
      if (res.data) {
        // open chat
        setOpenChat({
          state: true,
          chat: res.data
        });
        setTimeout(() => {
          setactiveTab('conv');
        }, 100);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const handleAddMember = (data) =>  {
    addMemberToGroup(activeUser.token, data.chatId, data.member._id)
    .then(res => {
      if (res.data) {
        setOpenChat({
          state: true,
          chat: res.data.added
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const handleRemoveMember = (data) =>  {
    removeMemberToGroup(activeUser.token, data.chatId, data.member._id)
    .then(res => {
      if (res.data) {
        setOpenChat({
          state: true,
          chat: res.data.removed
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  const handleShowImage = () => {
    setShowImageViewer(true);
  }

  const handleCloseImage = () => {
    setShowImageViewer(false);
  }

  const logoutHandler = () => {
    localStorage.removeItem("_user")
    navigate("/")
  }

  return (
    <div className="flex h-screen bg-white dark:bg-gray-950">
      <div className={`flex flex-col flex-shrink-0 w-[300px] xl:w-[320px] max-md:w-full bg-white dark:bg-gray-900 relative ${openChat.state && 'max-md:hidden'}`}>
        {/* Profil and search */}
        <div className="flex flex-col gap-2 p-3">
          {/* Online user profilebox */}
          <div className="flex flex-wrap justify-between items-center rounded-xl px-3 border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 py-4">
            {/* Profile photo */}
            <div className="flex gap-2">
              <div className={`relative flex-shrink-0 ${profileColor(userTag)} w-12 h-12 max-w-12 max-h-12 rounded-full flex items-center justify-center`}>
                {
                  activeUser.pic && activeUser.pic !== '' ?
                  <img onClick={handleShowImage} src={`${SOCKET_SERVER_URL}/users/${activeUser.pic}`} className='user-profile rounded-full' alt='profile'/>
                  :
                  <span className="font-bold">{userTag}</span>
                }
                {/* status */}
                <span className={`absolute h-4 w-4 border-2 ${STATUS[activeUser?.status].theme} border-white rounded-full -bottom-0 -right-1`}></span>
              </div>
              <div className="flex flex-col justify-between">
                <div className="flex items-center gap-1">
                  <h1 className='text-indigo-950 dark:text-indigo-400 font-bold line-clamp-2'>{activeUser?.name}</h1>
                  {/* Open user info */}
                  <button className='p-1 text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white'>
                    <BiCog className='w-5 h-5' onClick={() => setShowModal(true)} />
                  </button>
                </div>
                <p className='text-slate-700 dark:text-slate-300 text-sm'>{STATUS[activeUser?.status].label}</p>
              </div>
            </div>
            {/* Bell notification */}
            <div className="flex items-center justify-center pr-4 gap-3 ml-auto">
              <ThemSwitcher />
              <div className="relative">
                <BsBell className='w-5 h-5 text-slate-600 dark:text-slate-400' />
                <span className="absolute -top-1 -right-2 bg-rose-500 text-white text-xxxs rounded-full w-fit h-fit px-1">0</span>
              </div>
            </div>
          </div>
          {/* Tab (Contacts, Conversations) */}
          <div className="w-full grid grid-cols-2 gap-2 px-2">
            <button
              className={`${activeTab === 'conv' ? 'text-emerald-400' : 'text-gray-700 dark:text-gray-500'} p-2 rounded-lg hover:bg-white dark:hover:bg-gray-800 flex items-center flex-col hover:text-emerald-500`}
              onClick={() => setactiveTab('conv')}
            >
              <AiOutlineMessage className='w-7 h-7' />
              <p className='text-xs'>Conversations</p>
            </button>
            <button
              className={`${activeTab === 'cont' ? 'text-emerald-400' : 'text-gray-700 dark:text-gray-500'} p-2 rounded-lg hover:bg-white dark:hover:bg-gray-800 flex items-center flex-col hover:text-emerald-500`}
              onClick={() => setactiveTab('cont')}
            >
              <RiContactsBook2Line className='w-7 h-7' />
              <p className='text-xs'>Contacts</p>
            </button>
          </div>
        </div>

        <div className="div">
        </div>

        {/* Tab active */}
        {
          activeTab === 'conv' ?
          <Conversations
            onCloseChat={handleCloseConversation}
            onOpenChat={handleOpenConversation}
            activeChat={openChat.chat}
            visible={activeTab === 'conv'}
          /> :
          <Contacts
            visible={activeTab === 'cont'}
            onOpenChat={handleOpenConversation}
            onSayHi={handleSayHi}
          />
        }
      </div>
      {/* Open chat room */}
      {
      openChat.state &&
      <div className={`flex flex-grow flex-col`}>
        <Chat onTransfert={handleTransfert} onSendMessageTo={handleSendMessageTo} onCloseChat={handleCloseConversation} chat={openChat.chat} onAddMember={handleAddMember} onRemoveMember={handleRemoveMember} />
      </div>
      }
      <div className={`flex-grow flex-col hidden ${!openChat.state && 'md:flex'}`}>
        <div className="m-auto">
          <GiConversation className='w-32 h-32 text-emerald-500' />
          <p className='font-medium w-full text-slate-700 dark:text-gray-500'>Messagerie interne</p>
        </div>
      </div>
      {/* Modal */}
      {
        showModal && 
        <motion.div
          className="h-full w-full absolute px-2 top-0 left-0 bg-black/20 backdrop-blur-sm z-50 flex items-center justify-center">
            <motion.div 
              initial={{ y: '-100%'}}
              animate={{ y: showModal ? '0' : '-100%' }}
              className='w-full'
            >
              <UserActiveModal activeUser={activeUser} onLogout={logoutHandler} onClose={() => setShowModal(false)} onChange={handleUserChange} />
            </motion.div>
        </motion.div>
      }
      {
        (activeUser.pic && activeUser.pic !== "" && showImageViewer) &&
        <ImageViewer src={activeUser.pic}  onClose={handleCloseImage} />
      }
    </div>
  );
}

export default Messenger;
