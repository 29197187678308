import ReactDomServer from 'react-dom/server';
import { BsChevronDown, BsFillSendFill, BsEmojiSmile, BsReply } from 'react-icons/bs';
import { MdCancel, MdOutlineColorLens } from 'react-icons/md'
import { VscFiles } from 'react-icons/vsc'
import { RxCross2 } from 'react-icons/rx'
import { FiActivity, FiArrowLeft } from 'react-icons/fi'
import { IoMdRefreshCircle } from 'react-icons/io'
import { useEffect, useRef, useState } from 'react';
import EmojiPicker, { Emoji, EmojiStyle } from 'emoji-picker-react';
import { motion, useInView } from 'framer-motion';
import { MessageSender, MentionInput, MessageReceiver, MessageFileSender, MessageFileReceiver } from '../MentionComponents';
import { MessagesData, RandomMessages, emojifyText, firstChar, getChatData, getSelectionStart, getTag, groupByDate, groupMessages, includeReplies, insertElement, insertEmojiElement, insertNodeOverSelection, isFileSizeGreaterThan5MB, maximizeDisplay, minimize, profileColor, trimString } from '../../utils/tools';
import { PiImagesSquare } from 'react-icons/pi'
import { v1 } from 'uuid'
import moment from '../../utils/moment.cust';
import FilesList from '../FilesList';
import ScrollContainer from '../ui/ScrollContainer';
import { PiMagnifyingGlassBold } from 'react-icons/pi';
import ChatInput from '../ChatInput';
import ThemeSelector from './ThemeSelector';
import Typing, { UserIsTyping } from './Typing';
import Transfert from '../Transfert';
import axios from 'axios';
import Seen from './Seen'


import { deleteConversation, deleteMessage, getConversations, getUserIn, postConversation, putConversation, sendMessage, sendFile, session, updateMessage, updateTheme, SOCKET_SERVER_URL, readMessage, USER_PIC_DEFAULT } from '../../utils/func';
//Socket
import io from 'socket.io-client';
import { BiGroup } from 'react-icons/bi';
import MembersInGroup from '../MembersInGroup';
import AllImage from './AllImage';
import UserInfoModal from '../UserInfoModal';

function Chat({ onCloseChat, chat, onAddMember, onTransfert, onRemoveMember, onSendMessageTo }) {


  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const displayChats = groupByDate(messages);
  const [isGettingOldMessage, setIsGettingOldMessage] = useState(false);
  const [lastPreviousMessage, setLastPreviousMessage] = useState('');
  const [showEmoji, setShowEmoji] = useState(false);
  const [showMembers, setShowMembers] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [alreadySeen, setAlreadySeen] = useState([]);
  const initialState = {
    state: false,
    data: {}
  }
  const [showUserInfo, setShowUserInfo] = useState(initialState);
  //Is typing section
  const [someone, setSomeone] = useState("");
  const typingMessage = () => {
    socket.emit(`typing${chat._id}`, activeUser.name);
    socket.emit(`seen${chat._id}`, activeUser._id);
  }
  const [transfert, setTranfert] = useState(initialState);
  // theme (color & bg of chat)
  const [theme, setTheme] = useState('_default');
  const [showTheme, setShowTheme] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // state for replying
  const [isReplying, setIsReplying] = useState(initialState);
  // state for updating
  const [isUpdating, setIsUpdating] = useState(initialState);
  // uploaded files
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedSendFiles, setUploadedSendFiles] = useState([]);

  //trigger hooks
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [usersInChat, setUsersInChat] = useState([]);

  const [people, setPeople] = useState([]);
  const activeUser = session.user();
  const [anotherUser, setAnotherUser] = useState({});
  const groupedMessage = groupMessages(messages);
  const toogleImage = () => {
    setIsImageVisible(!isImageVisible)
  }
  // fetch message data and users in chat
  //Socket initialisation
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    setIsImageVisible(false)
    getConversations(activeUser.token, chat._id).then(res => {
      if (res.status === 200) {
        setMessages(res.data);
        setIsLoading(false);
      }
    });

    // show username if not a groupchat
    if (!chat.isGroupChat) {
      const user = chat.users.find(user => user._id !== activeUser._id);
      setAnotherUser(user);
    }

    chat.chatTheme && setTheme(chat.chatTheme);

    // set people to mention
    setPeople(chat.users.filter(user => user._id !== activeUser?._id))
    const socketConnection = io(SOCKET_SERVER_URL);
    socketConnection.on('connect', () => {
      console.log('Connected to server');
      //Mark all message in the chat as read
      const seenMessage = () => {
        readMessage(activeUser.token, { "id": chat._id, user: activeUser._id }).then(res => {
          socketConnection.emit(`seen${chat._id}`, activeUser._id);
        })
      }
      seenMessage();
    });
    //Socket for typing event
    socketConnection.on(`typing${chat._id}`, (sender) => {
      setIsTyping(true);
      setSomeone(sender);
      setTimeout(() => {
        setHasNewMessage(!hasNewMessage)
      }, 100);
      setTimeout(() => {
        setIsTyping(false);
      }, 10000);
    })

    //Receive message via socket

    socketConnection.on(chat._id, (get) => {
      get = JSON.parse(get);
      console.log("Get", get)
      setIsTyping(false);
      // Array of file message

      if (get.length) {
        get.map(message => {
          if (activeUser._id !== message.sender._id)
            setMessages(prev => [...prev, message]);
        })
      } else {
        if (get.isUpdate) {
          setMessages(prev => prev.map(message => {
            if (message._id === get._id) {
              return ({ ...message, reactions: get.reactions })
            }
            else {
              return message
            }
          }
          ));
        }
        else {
          // simple message
          if (activeUser._id !== get.sender._id) {
            setMessages(prev => [...prev, get]);
          }
        }

      }
      setMessage('');
      // trigger to scroll to bottom
      setHasNewMessage(!hasNewMessage);
    })
    // 

    setSocket(socketConnection);
    //Set the room as active on the socket
    const createRoom = () => {
      socketConnection.emit("room", chat._id);
    }
    createRoom();

    chat.latestMessage?.isSeen.map((seen, index) => {
      if (seen.user._id !== activeUser._id) {
        alreadySeen.push(seen.user);
      }
    })
    // Clean up the socket connection when the component unmounts
    return () => {
      socketConnection.disconnect();
    };
  }, [chat]);

  const messageInputRef = useRef();
  const uploaderRef = useRef(null);

  const handleUploadFiles = (files) => {

    // setIsReplying({
    //   state: true,
    //   data: message
    // });
    // setIsUpdating(initialState);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!isFileSizeGreaterThan5MB(file)) {
        setUploadedFiles(prev => [...prev, file]);
      } else {
        alert("Fichier est volumineux, on ne peux pas envoyer un fichier de taille supérieur à 5MB")
      }
    }
  }

  const handleSendMessage = async () => {
    if (uploadedFiles.length > 0) {
      // setIsReplying({state: false})
      const formData = new FormData();
      uploadedFiles.map((file, i) => {
        formData.append(`files`, file);
        formData.append('chatId', chat._id);
        formData.append('filename', file.name);
        formData.append('size', file.size);
      });

      sendFile(activeUser.token, formData).then(res => {
        if (res.data) {

          setMessages(prev => [...prev, ...res.data]);
          setUploadedFiles([]);
          setHasNewMessage(!hasNewMessage)
          if (socket) {
            //Send the message through socket
            socket.emit(chat._id, JSON.stringify(res.data))
          }
        }
      }).catch(err => {
        console.log(err);
      });



    }

    if (messageInputRef.current.innerHTML.trim().length === 0 &&
      !messageInputRef.current.innerHTML.includes('<img')) return;

    const minimized = minimize(messageInputRef.current);
    const trimed = trimString(minimized);
    const message = trimed;

    // reply message
    if (isReplying.state) {

      sendMessage(activeUser.token, {
        chatId: chat._id,
        content: message,
        isReply: true,
        reply: isReplying.data._id
      }).then(res => {
        if (res.data) {
          setMessages(prev => [...prev, res.data]);
          setMessage('');
          // trigger to scroll to bottom
          setHasNewMessage(!hasNewMessage);
        }
      }).catch(err => {
        console.log(err);
      });
      // cancel reply
      handleCancelReply();

    }
    // updating message
    else if (isUpdating.state) {
      const messageId = isUpdating.data._id;
      // console.log(messageId)
      const messageToEdit = messages.find(m => m._id === messageId)
      updateMessage(activeUser.token, {
        ...messageToEdit, content: message, messageId: messageId
      }).then(res => {
        if (res.data) {
          setMessages(prev => prev.map(mess =>
            mess._id === messageId ?
              { ...res.data } : mess
          ));
          setIsUpdating(initialState);
        }
      })
    }
    // send simple message
    else {
      sendMessage(activeUser.token, {
        chatId: chat._id,
        content: message,
        isReply: false
      }).then(res => {
        if (res.data) {
          setMessages(prev => [...prev, res.data]);
          setMessage('');
          // trigger to scroll to bottom
          setHasNewMessage(!hasNewMessage);
          if (socket) {
            //Send the message through socket
            socket.emit(chat._id, JSON.stringify(res.data))
          }

        }
      }).catch(err => {
        console.log(err);
      });
    }
    messageInputRef.current.innerHTML = '';
    setShowEmoji(false);
  }

  // Message reaction handler
  const handleReaction = (props) => {
    const { messageId, reaction, isRemoving } = props;
    const messageToUpdate = messages.find(m => m._id === messageId);
    const reactions = [...messageToUpdate.reactions];
    const senderReaction = reactions.find(r => r.user._id === activeUser._id);
    if (senderReaction) {
      if (isRemoving)
        // Remove reaction
        reactions.splice(reactions.indexOf(senderReaction), 1);
      else
        // Update reaction
        reactions.splice(reactions.indexOf(senderReaction), 1, reaction)
    } else {
      reactions.push(reaction);
    }

    const reactedMessage = {
      ...messageToUpdate,
      reactions: reactions
    }

    updateMessage(activeUser.token, reactedMessage).then(res => {
      if (res.data) {
        if (socket) {
          res.data.isUpdate = true;
          socket.emit(res.data.chat._id, JSON.stringify(res.data))
        }
        setMessages(prev => prev.map(message =>
          message._id === messageId ? res.data : message
        ));
      }
    });
  }

  // handle reply message
  const handleReplyMessage = (message) => {
    setIsReplying({
      state: true,
      data: message
    });
    setIsUpdating(initialState);

    // if (messageInputRef.current)
    //   messageInputRef.current.containerElement.querySelector('textarea').focus()
  }

  // handle cancel reply message
  const handleCancelReply = () => {
    setIsReplying({
      state: false,
      data: {}
    });
  }

  // handle pick emoji
  const handlePickEmoji = (selectedEmoji) => {
    const ref = messageInputRef.current;
    if (!ref) return;
    ref.focus();
    insertEmojiElement(messageInputRef, { emoji: selectedEmoji, size: 14 });
  }

  // delete message 
  const handleDeleteMessage = (msg) => {
    deleteMessage(activeUser.token, msg._id).then(res => {
      if (res.data && res.data.deletedCount === 1) {
        setMessages(prev => prev.filter(m => m._id !== msg._id));
      }
    });
  }

  // unread message 
  const handleUnreadMessageFromHere = (msg) => {
    setMessages(prev => prev.map(
      m => ({ ...m, isRead: m.id !== msg.id })
    ));
  }
  // read message
  const handleReadMessage = (messagesId) => {
    if (!messagesId) return;
    setTimeout(() => {
      setMessages(prev => prev.map(m => m.id === messagesId ? ({ ...m, isRead: true }) : m));
    }, 1500);

  }

  //transfert message 
  const handleTransfertMessage = (transfertData) => {
    // console.log(transfertData)
    onTransfert && onTransfert(transfertData).then(res => {
      setTranfert(false);
    }).catch(err => {
      alert('transfert error')
    });

  }
  // open transfert message form
  const handleOpenTransfert = (message) => {
    // TODO
    setTranfert({
      state: true,
      data: message
    });

  }

  // update message
  const handleUpdateMessage = (msg) => {
    // cancel reply while updating
    setIsReplying(initialState);
    setIsUpdating({ state: true, data: msg });
    // setMessage
    setMessage(maximizeDisplay(msg.content, true));
    // focus textarea
    // messageInputRef.current.containerElement.querySelector('textarea').focus();
  }

  const handleCancelUpdate = () => {
    setIsUpdating(initialState);
    setMessage('');
  }


  // drag drop file component
  // drag state
  const [dragActive, setDragActive] = useState(false);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };
  const handleDragLeave = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // upload file
      handleUploadFiles(e.dataTransfer.files);
    }
  };

  // Update theme
  const handleChangeTheme = (theme) => {
    updateTheme(activeUser.token, chat._id, theme).then(res => {
      if (res.data) {
        setTheme(theme);
        setMessages(prev => [...prev, res.data.message])
      }
    }).catch(err => {

    })
  }


  // get old message
  const getOldMessage = (e) => {
    // setIsGettingOldMessage(true)

    // setIsGettingOldMessage(false);
  }

  // show user info while clicking on the tag
  const handleUserInfo = (e) => {
    const id = e.target.id;
    if (id === activeUser._id) return;
    setShowUserInfo({
      state: true,
      data: chat.users.find(user => user._id === id)
    });
  }

  useEffect(() => {
    // mentioned people in the chat
    const mentions = document.querySelectorAll('.mentioned_people.bold');
    mentions.forEach(element => {
      element.addEventListener('click', handleUserInfo)
    })

    return () => {
      mentions.forEach(element => {
        element.removeEventListener('click', handleUserInfo)
      })
    }
  }, [messages]);


  if (isLoading) return (
    <div className='w-full h-screen flex'>
      <div className="m-auto flex items-center justify-center flex-col">
        <div className={`relative w-20 h-20 rounded-full flex items-center justify-center
            ${profileColor(getTag(chat.isGroupChat ? chat.chatName : anotherUser.name))}`}>
          <span className="font-bold text-3xl uppercase">
            {getTag(chat.isGroupChat ? chat.chatName : anotherUser.name)}
          </span>
        </div>
        <h1 className="text-center text-xl my-3 text-black dark:text-white">{chat.isGroupChat ? chat.chatName : anotherUser.name}</h1>
        <h1 className="text-gray-800 dark:text-gray-200 animate-pulse">Chargement...</h1>
      </div>
    </div>
  )

  return (
    <div className="flex h-screen flex-col bg-gray-100 dark:bg-slate-900"
      onDragOver={handleDrag}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="flex items-center justify-between px-2 bg-white dark:bg-gray-800 border-b border-slate-300 dark:border-gray-600 py-3">
        {/* Active room */}
        <div className="flex items-center gap-2">
          <div className='flex items-center justify-center md:hidden'>
            {/* Close chat */}
            <button className="flex-gr text-slate-600 dark:text-gray-400 hover:text-slate-800"
              onClick={onCloseChat}
            >
              <FiArrowLeft className='w-6 h-6' />
            </button>
          </div>
          <div className={`relative w-10 h-10 rounded-full flex items-center justify-center
            ${profileColor(getTag(chat.isGroupChat ? chat.chatName : anotherUser.name))}`}>
            {
              chat.isGroupChat ?
                <span className="font-bold text-base uppercase">{getTag(chat.chatName)}</span>
                :
                anotherUser.pic === '' ?
                  <span className="font-bold text-base uppercase">{getTag(chat.name)}</span>
                  :
                  <img src={anotherUser.pic === USER_PIC_DEFAULT ? anotherUser.pic : `${SOCKET_SERVER_URL}/users/${anotherUser.pic}`}
                    className='rounded-full user-profile'
                  />
            }
          </div>
          <div className="text-slate-500 dark:text-gray-300 flex flex-col justify-between">
            <h1 className='text-sm font-semibold break-all'>
              {
                chat.isGroupChat ? chat.chatName : anotherUser.name
              }
            </h1>
            {/* Show status if not a group room */}
            {
              !chat.isGroupChat ?
                <div className='flex items-center gap-1 text-xs line-clamp-1'>
                  <span className='h-fit w-fit p-1 border-2 bg-green-500 border-white rounded-full'></span>
                  {/* Status */}
                  <span>Actif maintenant</span>
                </div>
                :
                <p className='text-sm'>{chat.users.length} membres</p>
            }
          </div>
        </div>
        {/* search and gallery */}
        <div className='flex gap-3 items-center px-2'>
          {
            chat.isGroupChat ?
              <>
                {
                  chat.groupAdmin._id === activeUser._id &&
                  <>
                    <button className='p-1 text-slate-500 dark:text-gray-400 hover:text-slate-800'
                      onClick={() => setShowMembers(true)}
                    >
                      <BiGroup className='w-6 h-6' />
                    </button>
                    <button className='p-1 text-slate-500 dark:text-gray-400 hover:text-slate-800'
                      onClick={() => setShowTheme(true)}
                    >
                      <MdOutlineColorLens className='w-5 h-5' />
                    </button>
                  </>
                }
              </> :
              <button className='p-1 text-slate-500 dark:text-gray-400 hover:text-slate-800'
                onClick={() => setShowTheme(true)}
              >
                <MdOutlineColorLens className='w-5 h-5' />
              </button>
          }
          {/* <button className='p-1 text-slate-500 dark:text-gray-400 hover:text-slate-800'>
            <PiMagnifyingGlassBold className='w-5 h-5' />
          </button> */}
          <button className='p-1 text-slate-500 dark:text-gray-400 hover:text-slate-800' onClick={toogleImage}>
            <PiImagesSquare className='w-6 h-6' />
          </button>

          {/* Theme selector */}
          <motion.div className="absolute top-20 right-4 z-[1000] hidden"
            animate={{ display: showTheme ? 'block' : 'none' }}
          >
            <ThemeSelector setTheme={handleChangeTheme} currentTheme={theme} onClose={() => setShowTheme(false)} />
          </motion.div>
        </div>
      </div>


      <ScrollContainer
        dragActive={false}
        haveNewMessage={hasNewMessage}
        onScrollTop={getOldMessage}
        onReadMessage={handleReadMessage}
      >
        {
          isGettingOldMessage &&
          <div className="absolute top-2 w-full flex">
            <div className="shadow-lg rounded-2xl px-3 py-2 border border-slate-200 z-50 bg-white dark:bg-gray-800 flex items-center justify-center w-fit mx-auto">
              <IoMdRefreshCircle className='animate-spin h-8 w-8 text-emerald-500' />
            </div>
          </div>
        }
        {/* <!-- Individual chat message --> */}
        {
          //User typing component, false if not chat item
          isTyping && <UserIsTyping someone={someone} chatitem={false} />
        }
        {

          displayChats.map((item, index) => {
            return (
              // console.log();
              <motion.div key={index}
                className='flex flex-col opacity-0 duration-500'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <fieldset className='border-t border-slate-200 dark:border-gray-700 my-5'>
                  <legend className='text-center text-xs px-3 text-slate-500'>{moment(new Date(item.date)).calendar()}</legend>
                </fieldset>
                <div className="w-full flex flex-col">
                  {
                    item.messages.map((msg, i) => {
                      // show updated theme notification
                      if (msg.isThemeNotification) {
                        return (<div className="w-full flex-wrap flex my-2" key={`message-${msg._id}-${i}`}>
                          <div className='items-center flex-wrap justify-center flex gap-1 text-gray-600 dark:text-gray-500 mx-auto text-center text-xs'>
                            <span className="font-semibold">
                              {msg.themeNotification.user._id === activeUser._id ? 'Vous' : msg.themeNotification.user.name}
                            </span>
                            {msg.themeNotification.user._id === activeUser._id ? 'avez' : 'a'} changé le theme du conversation en
                            <div className={`rounded-full h-4 w-4 flex-shrink-0 my-2 ${msg.themeNotification.theme}`}></div>
                          </div>
                        </div>)
                      }
                      // Show group notification message
                      if (msg.isGroupNotification) {
                        return (<div className="w-full flex-wrap flex my-2" key={`message-${msg._id}-${i}`}>
                          <div className='items-center flex-wrap justify-center flex gap-1 text-gray-600 dark:text-gray-500 mx-auto text-center text-xs'>
                            {
                              (msg.groupNotification.from._id === activeUser._id) ?
                                <p><span className='font-semibold'>Vous</span> avez {msg.groupNotification.action === 1 ? 'ajouté' : 'supprimé'} <span className='font-semibold'>{msg.groupNotification.to.name}</span> dans ce groupe.</p>
                                : /* else to === activeUser */
                                (msg.groupNotification.to._id === activeUser._id) ?
                                  <p><span className='font-semibold'>{msg.groupNotification.from.name}</span> vous a {msg.groupNotification.action === 1 ? 'ajouté' : 'supprimé'} dans ce groupe.</p>
                                  :
                                  <p><span className='font-semibold'>{msg.groupNotification.from.name}</span> a {msg.groupNotification.action === 1 ? 'ajouté' : 'supprimé'} <span className='font-semibold'>{msg.groupNotification.to.name}</span> dans ce groupe.</p>
                            }
                          </div>
                        </div>)
                      }

                      return (
                        <div className="w-full flex flex-col" key={`message-${msg._id}-${i}`}>
                          {msg.sender && msg.sender?._id === activeUser._id ?

                            (msg.filename) ?

                              <MessageFileSender message={msg}
                                onDelete={handleDeleteMessage}
                                onReply={handleReplyMessage}
                                onUpdate={handleUpdateMessage}
                                onTransfert={() => handleOpenTransfert(msg)}
                                // user={usersInChat.find(u => u.id === msg.sender)}
                                theme={theme}
                              />
                              :
                              <MessageSender message={msg}
                                onDelete={handleDeleteMessage}
                                onReply={handleReplyMessage}
                                onUpdate={handleUpdateMessage}
                                onTransfert={() => handleOpenTransfert(msg)}
                                activeUser={activeUser}
                                theme={theme}
                              />
                            :
                            <>
                              {/* Display not seen indicator */}
                              {msg.isRead &&
                                <motion.fieldset
                                  className={`border-t-2 border-gray-300 dark:border-gray-700 my-4`}
                                  initial={{ y: -100, opacity: 0 }}
                                  animate={{ y: 0, opacity: 1 }}
                                >
                                  <legend className={`${chat.chatTheme} text-transparent bg-clip-text text-center text-sm px-3 py-2 font-bold`}>Nouveau message</legend>
                                </motion.fieldset>
                              }
                              {(msg.filename) ?
                                <MessageFileReceiver message={msg}
                                  onReact={handleReaction}
                                  onReply={handleReplyMessage}
                                  onUnread={handleUnreadMessageFromHere}
                                  onTransfert={() => handleOpenTransfert(msg)}
                                  theme={theme}
                                  users={usersInChat}
                                />
                                :
                                <MessageReceiver message={msg}
                                  onReact={handleReaction}
                                  onReply={handleReplyMessage}
                                  onUnread={handleUnreadMessageFromHere}
                                  onTransfert={() => handleOpenTransfert(msg)}
                                  theme={theme}
                                  activeUser={activeUser}
                                  users={usersInChat}
                                />}
                            </>
                          }
                          <div className='flex self-end ml-auto gap-2 my-1 justify-end'>
                            {
                              (chat.latestMessage && chat.latestMessage._id === msg._id) &&
                              msg.isSeen.map((seen, index) => {
                                if (activeUser._id !== seen.user._id) {
                                  return <Seen message={msg.isSeen[index].user.name} />
                                }
                              })
                            }
                            {
                              (chat.latestMessage && chat.latestMessage._id !== msg._id) &&
                              msg.isSeen.map((seen, index) => {
                                if (item.messages[i + 1]) {
                                  if (item.messages[i + 1].isSeen.find(user => msg.isSeen[index].user.name === user.user.name)) {

                                  }
                                  else {
                                    if (activeUser._id !== seen.user._id) {
                                      if (alreadySeen.includes(seen.user._id) === false) {
                                        return <Seen message={msg.isSeen[index].user.name} />
                                      }
                                    }
                                  }
                                }
                              }
                              )
                            }
                          </div>
                        </div>
                      )
                    })
                  }

                  {/* <div className='w-full flex items-center gap-1 justify-end px-4'>
                    <div className="w-5 h-5 rounded-full bg-indigo-100 text-indigo-500 border border-indigo-200 text-xxxs flex items-center justify-center">
                      <span className='font-bold uppercase'>{firstChar('sender')}</span>
                    </div>
                  </div> */}
                </div>
              </motion.div>
            )
          })

        }


        {/* User profile */}
        <div className='w-full py-20 mb-20 text-center flex font-bold text-slate-700'>
          <div className="mx-auto border-b border-gray-200 dark:border-gray-800">
            <div className={`relative mx-auto w-20 h-20 rounded-full flex items-center justify-center animate-bounce
              ${profileColor(getTag(chat.isGroupChat ? chat.chatName : anotherUser.name))}`}>
              <span className="font-bold text-3xl uppercase">
                {getTag(chat.isGroupChat ? chat.chatName : anotherUser.name)}
              </span>
            </div>
            <h1 className="text-black dark:text-white bold my-1 text-lg break-all">{chat.isGroupChat ? chat.chatName : anotherUser.name}</h1>
            <p className="text-gray-700 dark:text-gray-300 text-sm font-normal pb-2 break-all">{!chat.isGroupChat ? anotherUser.email : `${chat.users.length} membres`}</p>
          </div>
        </div>

        <AllImage showSidebar={isImageVisible} id={chat._id} />

      </ScrollContainer>

      <div className='flex flex-col items-center w-full p-3 gap-2 relative bg-gray-50 dark:bg-gray-950'>
        <div className={`bg-white dark:bg-gray-900 w-full max-w-6xl mx-auto flex-grow text-sm rounded-3xl ${showEmoji && 'rounded-t-lg rounded-tr-lg'} border border-gray-300 dark:border-gray-800 px-2 py-1 relative`}>
          {/* Emoji fields */}
          <motion.div className='w-full overflow-hidden hidden' animate={{
            display: showEmoji ? 'block' : 'none',
          }}>
            <EmojiPicker width={'100%'} height={330}
              searchPlaceHolder='Chercher...'
              skinTonesDisabled
              emojiStyle={EmojiStyle.GOOGLE}
              theme='auto'
              previewConfig={{
                showPreview: false,
              }}
              onEmojiClick={handlePickEmoji}
            />
          </motion.div>

          {/* Message to reply */}
          {
            isReplying.state &&
            <motion.div
              // animate={{ opacity: [0, 1] }}
              className="w-full border-b border-slate-300 p-3 flex items-start gap-1  text-gray-900 dark:text-gray-50">
              <BsReply className='w-4 h-4 flex-shrink-0' />
              <div className="flex flex-col flex-grow">
                <p className='line-clamp-3 font-normal chatbox-input'
                  dangerouslySetInnerHTML={{ __html: maximizeDisplay(emojifyText(isReplying.data.content)) }}
                />
                <p className='text-slate-600 dark:text-gray-400'>
                  {isReplying.data.sender.name}, 10/07/2023
                </p>
              </div>
              <button className="p-1 hover:text-rose-600 text-slate-700" onClick={handleCancelReply}>
                <RxCross2 className='w-4 h-4 flex-shrink-0' />
              </button>
            </motion.div>
          }
          {/* Uploaded files */}
          <input type="file" ref={uploaderRef} name="files" hidden multiple onChange={(e) => handleUploadFiles(e.target.files)} />
          <FilesList files={uploadedFiles} changeFiles={setUploadedFiles} onAddFile={() => uploaderRef.current?.click()} />

          <div className="flex items-center gap-1 relative" id='mention_default'>
            <button type="button" className='p-1 hover:bg-indigo-100 dark:hover:text-gray-400 dark:hover:bg-gray-700 text-slate-500 hover:text-slate-800 rounded-full'
              onClick={() => setShowEmoji(!showEmoji)}
            >
              {!showEmoji ? <BsEmojiSmile className='h-5 w-5' /> : <BsChevronDown className='h-5 w-5' />}
            </button>
            {
              uploadedFiles.length === 0 &&
              <button key={2}
                className="flex items-center justify-center flex-grow-0 p-1 rounded-full text-slate-500 hover:bg-indigo-100 dark:hover:text-gray-400 dark:hover:bg-gray-700 hover:text-slate-800 h-fit"
                onClick={() => uploaderRef.current?.click()}
              >
                <VscFiles className='h-5 w-5' />
              </button>
            }

            {/* <TextInput mentionData={mentionData} /> */}
            <ChatInput value={message} ref={messageInputRef} onChange={typingMessage} onSubmit={handleSendMessage} people={people} />
            {
              isUpdating.state &&
              <button key={1} className="rounded-full text-rose-400 hover:bg-gray-300 hover:text-rose-500 p-2" onClick={handleCancelUpdate} >
                <MdCancel className='h-5 w-5' />
              </button>
            }
            {
              // message.trim().length > 0 &&
              <motion.button disabled={message.trim().length === -1}
                whileHover={{ scale: 1.3 }}
                className={`rounded-full ${theme} font-bold text-transparent bg-clip-text dark:hover:bg-gray-700 p-2 disabled:cursor-not-allowed`}
                onClick={handleSendMessage} >
                {/* <BsFillSendFill className='h-5 w-5' /> */}
                <i aria-hidden className='fa fa-paper-plane text-xl' />
              </motion.button>
            }
          </div>
        </div>
      </div>

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: transfert.state ? [1.1, 1] : 0 }}
        className="fixed inset-0 flex px-6 items-center justify-center bg-black/10 backdrop-blur-sm z-[1000]"
      >
        {transfert.state && <Transfert chatId={chat._id} activeUser={activeUser} onTransfert={handleTransfertMessage} theme={theme} data={transfert.data} onClose={() => setTranfert(false)} />}
      </motion.div>

      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: showMembers ? [1.1, 1] : 0 }}
        className="fixed inset-0 flex items-center justify-center bg-black/10 backdrop-blur-sm z-[1000]"
      >
        {showMembers && <MembersInGroup
          session={activeUser} onClose={() => setShowMembers(false)}
          chatId={chat._id}
          members={chat.users.filter(user => user._id !== activeUser._id)}
          onAdd={onAddMember}
          onRemove={onRemoveMember}
        />}
      </motion.div>
      {/* User info modal */}
      {
        showUserInfo.state &&
        <div className="absolute p-6 z-[1000] top-0 left-0 bg-black/30 backdrop-blur-sm w-full h-full flex items-center justify-center">
          <UserInfoModal onSendMessage={onSendMessageTo} theme={theme} user={showUserInfo.data} onClose={() => setShowUserInfo(initialState)} />
        </div>
      }
    </div>
  );
}

export default Chat;