import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'
import { BiConversation, BiSearch } from 'react-icons/bi';
import { RxMagnifyingGlass } from 'react-icons/rx';
import ChatItem from '../chat/ChatItem';
import ContactItem from './ContactItem';
import { RandomUsers } from '../../utils/tools';
import { getUsers, searchContacts } from '../../utils/func';
import useSession from '../../hooks/useSession';

import axios from 'axios'

const Contacts = ({ visible, onOpenChat, onSayHi }) => {
    const initialSearch = {
      focus: false,
      value: ''
    }
    
    // const [search, setSearch] = useState(initialSearch);
    const [search, setSearch] = useState("");
    const [searchResult, setSearchResult] = useState([])
    const [activeChatId, setActiveChatId] = useState(null);
    const [contacts, setContacts] = useState([]);
    const { session } = useSession();

    const handleOpenChat = (id) => {
        setActiveChatId(id);
        onOpenChat({state: true, chatId: id})
    }

    useEffect(() => {
        // setContacts(RandomUsers());
        if (session) {
            
            searchContacts(session.token, '').then(res => {
                if (res.data) 
                    setSearchResult(res.data)
            });
        }
    }, [session]);

    if (!visible) return <></>;

    const searchContact = async (event) => {
        setSearch(event.target.value)
        // console.log("searchContact");
        // if ( search ) {

            // socket.emit("stop typing", selectedChat._id)
            try {

                const { data } = await searchContacts(session.token, event.target.value);

                setSearchResult(data)

            } catch (error) {
                console.log("error", error);
            }
        // }
    }

    
    return (
    <>
        {/* search contacts */}
        <div className="px-2 my-2">
            {/* <form onKeyDown={searchContact} isRequired> */}
            <div  className="flex items-center gap-1 w-full bg-gray-100 dark:bg-gray-800 rounded-full p-2 cursor-pointer group">
                <RxMagnifyingGlass className='text-slate-600 dark:text-gray-500 group-hover:text-indigo-500' />
                <input type="search"
                    placeholder='Chercher...'
                    value={search}
                    className='py-1 px-1 text-xs flex-grow bg-transparent placeholder:text-slate-600 dark:placeholder:text-gray-500 outline-none focus:text-slate-800'
                    // onFocus={() => setSearch(prev => ({ ...prev, focus: true }))}
                    // onBlur={() => setSearch(prev => ({ ...prev, focus: false }))}
                    onChange={searchContact}//setSearch(prev => ({ ...prev, value: e.target.value }))}
                />
            </div>
            {/* </form> */}
        </div>

        {/* Chats */}
        <div className="chats p-2 flex-grow scrollbox scrollbox_delayed w-full">
            {/* Conversations list */}
            <div className="scrollbox-content w-full">
                <div className="w-full">
                    <fieldset className='border-t border-gray-300 dark:border-gray-600'>
                        <legend className='text-sm font-bold text-gray-700 dark:text-gray-400 my-2 flex items-center gap-1 px-1 ml-2'>
                            <span>Contacts</span>
                        </legend>
                    </fieldset>
                    <div className="flex flex-col gap-1 pb-2">
                        
                    {
                        searchResult.length <= 0 ?
                            <p className='text-sm text-gray-600 dark:text-gray-400 px-2 text-center'>Aucuns contacts trouvées</p>
                        : searchResult.map((chat, i) => (  
                            
                            <ContactItem
                                key={i}
                                data={chat}
                                // contactId={chat}
                                onSayHi={() => onSayHi(chat)}
                            />
                        ))
                    }
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Contacts