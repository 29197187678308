import { motion } from 'framer-motion'
import React from 'react'
import { getTag, profileColor } from '../../utils/tools'
import { SOCKET_SERVER_URL, USER_PIC_DEFAULT } from '../../utils/func'

const ContactItem = ({ data, openChat, contactId, onSayHi }) => {

    const handleOpenChat = () => {
        openChat && openChat(contactId)
    }
    const tag = getTag(data.name);

    return (
        <motion.div
            className="flex items-center gap-2 p-2 px-3 rounded-lg hover:bg-indigo-200 dark:hover:bg-sky-950 relative group"
            onClick={handleOpenChat}
        >
            {/* Profile */}
            <div className={`relative flex-shrink-0 w-10 h-10 ${profileColor(tag)} rounded-full flex items-center justify-center`}>
                {
                    !data.pic || data.pic === '' ?
                    <span className="font-bold text-base uppercase">{tag}</span>
                    :
                    <img src={data.pic === USER_PIC_DEFAULT ? data.pic : `${SOCKET_SERVER_URL}/users/${data.pic}`} 
                        className='rounded-full user-profile'
                    />
                }
            </div>
            <div className="flex flex-col justify-center gap-1">
                <h1 className='text-sm font-bold text-gray-600 dark:text-gray-400 break-all'>{data.name}</h1>
                <div className="flex gap-1">
                    <span className='text-xs text-gray-600 dark:text-gray-500 flex-wrap break-all'>{data.email}</span>
                </div>
            </div>
            {
                onSayHi && 
                <div className='hidden items-center justify-center absolute right-2 group-hover:flex z-50'>
                    <button className='p-2 bg-emerald-500 hover:bg-emerald-600 text-white font-bold text-sm rounded-full'
                        onClick={onSayHi}
                    >
                        Saluer
                    </button>
                </div>
            }
        </motion.div>
    )
}

export default ContactItem