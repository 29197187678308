import React, { useEffect, useRef, useState } from 'react'
import { PiMagnifyingGlass } from 'react-icons/pi'
import ContactItem from './contact/ContactItem'
import { MdCancel, MdClose, MdOutlineMobileScreenShare } from 'react-icons/md'
import { AiOutlineCheckSquare, AiOutlineUserAdd, AiOutlineUserDelete, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { RandomUsers, getChatData, getTag, profileColor } from '../utils/tools'
import { motion } from 'framer-motion'
import { getUsers } from '../utils/func'

const MembersInGroup = ({onClose, chatId, members, session, onAdd, onRemove}) => {

    const [search, setSearch] = useState('');
    const [groupMembers, setGroupMembers] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [activeTab, setActiveTab] = useState(1);

    const handleAddMember = (member) => {
        onAdd && onAdd({
            chatId: chatId,
            member: member,
        });
        // setGroupMembers(prev => [...prev, member]);
    }

    const handleRemoveMember = (member) => {
        onRemove && onRemove({
            chatId: chatId,
            member: member,
        });
        // setGroupMembers(prev => prev.filter(m => m._id !== member._id));
    }

    const filter = (contacts) => {
        const key = search.toLowerCase();
        return contacts.filter(contact => contact.email.toLowerCase().match(key) || contact.name.toLowerCase().match(key));
    }

    const notMembers = (contacts) => {
        return contacts.filter(contact => !groupMembers.some(member => contact._id === member._id));
    }

    useEffect(() => {
        setGroupMembers(members)
    }, [members]);
    
    useEffect(() => {
        setSearch('');
    }, [activeTab]);
    
    useEffect(() => {
        getUsers(session.token).then(res => {
            if (res.status === 200) {
                setContacts(res.data);
            }
        });
    }, []);
    

    return (
        <div className='w-full max-w-xl bg-white dark:bg-slate-800 rounded-lg p-4 shadow-md'>
            <div className="flex items-center justify-between pb-2 border-b border-gray-300 dark:border-gray-700">
                <h1 className='text-gray-700 dark:text-gray-200 flex gap-2'>
                    <AiOutlineUsergroupAdd className='w-6 h-6' />
                    Membres du groupe
                </h1>
                <button className='text-gray-500 dark:hover:text-white hover:text-black'
                    onClick={() => onClose && onClose()}
                >
                    <MdClose className='w-6 h-6' />
                </button>
            </div>
            <div className="px-2">
                <div className="flex flex-col my-2">
                    <div className='text-black dark:text-white text-xs py-1 flex gap-1'>
                        <button className={`${activeTab === 1 && 'bg-gray-200 dark:bg-gray-700'} p-2 text-black dark:text-white border border-gray-200 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-sm`}
                            onClick={() => setActiveTab(1)}
                        >MEMBRES ACTUELS</button>
                        <button className={`${activeTab === 2 && 'bg-gray-200 dark:bg-gray-700'} p-2 text-black dark:text-white border border-gray-200 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-sm`}
                            onClick={() => setActiveTab(2)}
                        >AJOUTER MEMBRES</button>
                    </div>
                    {
                        activeTab === 1 &&
                        <div className="p-2 rounded-lg border bg-gray-50 dark:bg-gray-900 border-gray-300 dark:border-gray-700">
                            <div className="flex items-center p-2 gap-2">
                                <PiMagnifyingGlass className='flex-shrink-0 text-gray-500'/>
                                <input
                                    type="search"
                                    onChange={(e) => setSearch(e.currentTarget.value)}
                                    className='text-sm text-black dark:text-white flex-grow bg-transparent outline-none'
                                    placeholder='Chercher...'
                                    autoComplete='new-password'
                                />
                            </div>
                            <div className="flex flex-col overflow-y-auto h-fit max-h-[230px] py-1">
                                {
                                    filter(groupMembers).map((member, i) => (
                                        <MemberRemoveItem key={member._id} data={member} onRemove={handleRemoveMember} />
                                    ))
                                }
                            </div>
                            <div className="bg-transparent p-1 px-2">
                                <h1 className='text-black dark:text-white text-sm'>{members.length} membres</h1>
                            </div>
                        </div>
                    }
                    
                    {
                        activeTab === 2 &&
                        <div className="p-2 rounded-lg border bg-gray-50 dark:bg-gray-900 border-gray-300 dark:border-gray-700">
                            <div className="flex items-center p-2 gap-2">
                                <PiMagnifyingGlass className='flex-shrink-0 text-gray-500'/>
                                <input
                                    type="search"
                                    onChange={(e) => setSearch(e.currentTarget.value)}
                                    className='text-sm text-black dark:text-white flex-grow bg-transparent outline-none'
                                    placeholder='Chercher...'
                                    autoComplete='new-password'
                                />
                            </div>
                            <div className="flex flex-col overflow-y-auto h-fit max-h-[230px] py-1">
                                {
                                    notMembers(filter(contacts)).map((contact, i) => (
                                        <MemberAddItem key={contact._id} onAdd={handleAddMember} data={contact} />
                                    ))
                                }
                            </div>
                            <div className="bg-transparent p-1 px-2">
                                <h1 className='text-black dark:text-white text-sm'>{notMembers(contacts).length} membres</h1>
                            </div>
                        </div>
                    }
                    <div className="w-full flex mt-2">
                        {/* <button
                            className="p-2 text-white text-sm bg-emerald-600 rounded-md mx-auto disabled:cursor-not-allowed disabled:opacity-60"
                            onClick={handleAddPeople}
                            disabled={members.length < 2}
                        >
                            Fermer
                        </button> */}
                    </div>
                </div>

            </div>
        </div>
    )
}


const MemberRemoveItem = ({data, onRemove}) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const [timerId, setTimerId] = useState(null);

    const handleRemoveMember = () => {
        if (isProcessing) return;
        setIsProcessing(true);
        let newTimerId = setTimeout(() => {
            setIsProcessing(false);
            onRemove && onRemove(data);
        }, 3000);
        setTimerId(newTimerId);
    }

    const cancel = () => {
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
            setIsProcessing(false);
        }
    }

    useEffect(() => {
        return () => {
            if (timerId) clearTimeout(timerId);
        }
    }, []);

    return (
        <div key={data._id}
            className={`flex items-center gap-0 pr-3 roundedhover:bg-indigo-200 dark:hover:bg-sky-950`}
        >
            <motion.div
                className="flex items-center gap-2 p-2 px-3 rounded-lg relative group"
            >
                {/* Profile */}
                <div className={`relative flex-shrink-0 w-10 h-10 ${profileColor(getTag(data.name))} rounded-full flex items-center justify-center`}>
                    <span className="font-bold text-base uppercase">
                        {getTag(data.name)}
                    </span>
                </div>
                <div className="flex flex-col justify-center gap-1">
                    <h1 className='text-sm font-bold text-gray-600 dark:text-gray-400'>{data.name}</h1>
                    <div className="flex gap-1">
                        <span className='text-xs text-gray-600 dark:text-gray-500 flex-wrap break-all'>{data.email}</span>
                    </div>
                </div>
            </motion.div>
            {
                !isProcessing ?
                <button
                    className='ml-auto flex items-center gap-2 text-xs font-semibold bg-rose-600 dark:bg-rose-900 text-gray-200 dark:text-white p-2 rounded-lg'
                    onClick={() => handleRemoveMember(data)}
                >
                    <AiOutlineUserDelete className='w-6 h-6'/>
                </button>
                :
                <button
                    className='ml-auto flex items-center gap-2 text-xs font-semibold bg-amber-600 dark:bg-amber-900 text-gray-200 dark:text-white p-2 rounded-lg'
                    onClick={() => cancel()}
                >
                    <MdCancel className='w-6 h-6'/>
                </button>
            }
        </div>
    )
}



const MemberAddItem = ({data, onAdd}) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const [timerId, setTimerId] = useState(null);

    const handleAddMember = () => {
        if (isProcessing) return;
        setIsProcessing(true);
        let newTimerId = setTimeout(() => {
            setIsProcessing(false);
            onAdd && onAdd(data);
        }, 3000);
        setTimerId(newTimerId);
    }

    const cancel = () => {
        if (timerId) {
            clearTimeout(timerId);
            setTimerId(null);
            setIsProcessing(false);
        }
    }

    useEffect(() => {
        return () => {
            if (timerId) clearTimeout(timerId);
        }
    }, []);

    return (
        <div key={data._id}
            className={`flex items-center gap-0 pr-3 roundedhover:bg-indigo-200 dark:hover:bg-sky-950`}
        >
            <motion.div
                className="flex items-center gap-2 p-2 px-3 rounded-lg relative group"
            >
                {/* Profile */}
                <div className={`relative flex-shrink-0 w-10 h-10 ${profileColor(getTag(data.name))} rounded-full flex items-center justify-center`}>
                    <span className="font-bold text-base uppercase">
                        {getTag(data.name)}
                    </span>
                </div>
                <div className="flex flex-col justify-center gap-1">
                    <h1 className='text-sm font-bold text-gray-600 dark:text-gray-400'>{data.name}</h1>
                    <div className="flex gap-1">
                        <span className='text-xs text-gray-600 dark:text-gray-500 flex-wrap break-all'>{data.email}</span>
                    </div>
                </div>
            </motion.div>
            {
                !isProcessing ?
                <button
                    className='ml-auto flex items-center gap-2 text-xs font-semibold bg-emerald-600 dark:bg-emerald-900 text-gray-200 dark:text-white p-2 rounded-lg'
                    onClick={() => handleAddMember(data)}
                >
                    <AiOutlineUserAdd className='w-6 h-6'/>
                </button>
                :
                <button
                    className='ml-auto flex items-center gap-2 text-xs font-semibold bg-amber-600 dark:bg-amber-900 text-gray-200 dark:text-white p-2 rounded-lg'
                    onClick={() => cancel()}
                >
                    <MdCancel className='w-6 h-6'/>
                </button>
            }
        </div>
    )
}

export default MembersInGroup