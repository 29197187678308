import React, { useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import { SOCKET_SERVER_URL } from '../utils/func'
import { motion } from 'framer-motion'

const ImageViewer = ({src, onClose}) => {

    useEffect(() => {
        const body = document.body;
        const handleClose = (e) => {
            const key = e.keyCode || e.which;
            if (key === 27) onClose && onClose();
        }
        body.addEventListener('keydown', handleClose);
        return () => {
            body.removeEventListener('keydown', handleClose)
        }
    }, []);
    
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, animationDuration: 300 }}
            className='absolute top-0 left-0 z-[10000] bg-black/40 backdrop-blur-sm flex flex-col items-center justify-center w-full h-full p-8'>
            <button className='mb-auto ml-auto p-2'
                onClick={() => onClose && onClose()}
            >
                <IoMdClose className='w-8 h-8 text-white' />
            </button>
            <div className="flex flex-grow">
                <motion.img alt='image' src={`${SOCKET_SERVER_URL}/users/${src}`}
                    className='m-auto'
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                />
            </div>
        </motion.div>
    )
}

export default ImageViewer