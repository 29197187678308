import React, { useEffect, useRef, useState } from 'react'
import { PiMagnifyingGlass } from 'react-icons/pi'
import ContactItem from './contact/ContactItem'
import { MdClose, MdOutlineMobileScreenShare } from 'react-icons/md'
import { AiOutlineCheckSquare, AiOutlineUserAdd, AiOutlineUserDelete, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { RandomUsers, getChatData, getTag, profileColor } from '../utils/tools'
import useSession from '../hooks/useSession'
import { getUsers, session } from '../utils/func'
import { motion } from 'framer-motion'

const EditGroup = ({onClose, onUpdate, dataValue, activeUser}) => {

    const [name, setName] = useState('');

    const handleUpdateGroup = () => {
        const data = {
            chatName: name,
            chatId: dataValue._id
        }
        onUpdate && onUpdate(data);
    }

    useEffect(() => {
        
        if (!dataValue) return;
        
        setName(dataValue.chatName);

    }, [dataValue]);

    return (
        <div className='w-full max-w-xl bg-white dark:bg-slate-800 rounded-lg p-4 shadow-md'>
            <div className="flex items-center justify-between pb-2 border-b border-gray-300 dark:border-gray-700">
                <h1 className='text-gray-700 dark:text-gray-200 flex gap-2'>
                    <AiOutlineUsergroupAdd className='w-6 h-6' />
                    Modification du groupe
                </h1>
                <button className='text-gray-500 dark:hover:text-white hover:text-black'
                    onClick={() => onClose && onClose()}
                >
                    <MdClose className='w-6 h-6' />
                </button>
            </div>
            <div className="px-2">
                {/* Nom du groupe */}
                <div className="flex flex-col my-2">
                    <label htmlFor="group-name" className='text-black dark:text-white text-xs py-1'>
                        <li className="list-disc">Nom du groupe</li>
                    </label>
                    <input
                        onChange={e => setName(e.currentTarget.value)}
                        value={name}
                        type="text" id="group-name"
                        className='p-2 text-sm rounded-lg border text-black dark:text-white bg-gray-50 dark:bg-gray-900 border-gray-300 dark:border-gray-700 outline-none focus:ring-1 focus:ring-emerald-500'
                        placeholder='Ecrivez le nom du groupe...'
                        autoComplete='new-password'
                    />
                </div>
                <div className="flex flex-col my-2">
                    <div className="w-full flex mt-2">
                        <button
                            className="p-2 text-white text-sm bg-emerald-600 rounded-md mx-auto disabled:cursor-not-allowed disabled:opacity-60"
                            onClick={handleUpdateGroup}
                            disabled={name.trim().length < 2}
                        >
                            Mettre à jour le groupe
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EditGroup