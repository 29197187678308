import React, { useEffect, useRef, useState } from 'react'
import { STATUS, getTag, profileColor } from '../utils/tools';
import { PiPencil } from 'react-icons/pi';
import { BiChevronDown, BiChevronUp, BiLogOut, BiPencil, BiUser } from 'react-icons/bi';
import { BsCameraFill, BsEmojiAngryFill } from 'react-icons/bs';
import { MdAlternateEmail } from 'react-icons/md';
import { useClickAway } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import { SOCKET_SERVER_URL, session, updateUser } from '../utils/func';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ImFilePicture } from 'react-icons/im';

const UserActiveModal = ({ activeUser, onClose, onLogout, onChange }) => {

    const [userTag, setUserTag] = useState('');
    const [status, setStatus] = useState(STATUS[1]);
    const [imageTemp, setImageTemp] = useState(null);
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [pic, setPic] = useState('');
    const [editUser, setEditUser] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const ref = useClickAway(() => {
        onClose && onClose();
    });

    const fileRef = useRef();

    const handleSelectImage = () => {
        fileRef.current?.click();
    }
    
    const handlePickImage = (e) => {
        if (e.target.files.length === 0) return;
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setImageTemp(url);
        setImageFile(file);
    }

    const handleUpdateUser = () => {
        const formData = new FormData();
        if (imageFile) {
            formData.append('file', imageFile);
        } else {
            formData.append('pic', pic);
        }
        formData.append('name', name);
        formData.append('email', email);
        formData.append('status', status.value);
        setIsLoading(true);

        updateUser(activeUser.token, formData).then(res => {
            if (res.data) {
                setEditUser(false);
                setShowDropdown(false);
                session.setUser({...res.data, token: activeUser.token});
                console.log('User updated!', res.data);
                setShowDropdown(false);
                setEditUser(false);
                onChange && onChange(res.data);
            }
        }).catch(err => {
            console.log(err);
        }).finally(() => setIsLoading(false));
    }

    const handleRemovePhoto = () => {
        setImage(null);
        setImageTemp(null);
        setImageFile(null);
        setPic('');
    }


    useEffect(() => {
        setUserTag(getTag(activeUser.name));
        setImage(activeUser.pic);
        setName(activeUser.name)
        setEmail(activeUser.email)
        setPic(activeUser.pic)
        setStatus(STATUS[parseInt(activeUser.status) || 1]);
    }, [activeUser]);
    
    return (
        <div ref={ref} className='w-full bg-white dark:border dark:border-gray-700 dark:bg-gray-800 rounded-lg max-w-md shadow flex m-auto flex-col overflow-hidden'>
            {/* Profile photo */}
            <div className="flex gap-4 border-b pb-6 dark:border-gray-700 p-4 bg-gray-100 dark:bg-gray-800">
                <div className={`relative flex-shrink-0 ${profileColor(userTag)} w-14 h-14 max-w-14 min-h-14 rounded-full flex items-center justify-center`}>
                    {
                        imageTemp || image ?
                        <img src={imageTemp || `${SOCKET_SERVER_URL}/users/${image}`} alt="profil" referrerPolicy='no-referrer' className='user-profile rounded-full'/>
                        :
                        <span className="font-bold text-xl">{userTag}</span>
                    }
                    {
                        editUser &&
                        <button className={`absolute -bottom-4 left-[50%] translate-x-[-50%] p-1 rounded-full hover:text-black dark:hover:text-gray-600`}
                            onClick={handleSelectImage}
                        >
                            <BsCameraFill className='h-6 w-6 bg-white px-1 rounded-lg'/>
                            <input type="file" ref={fileRef} hidden accept='image/*' multiple={false} onChange={handlePickImage}/>
                        </button>
                    }
                </div>
                <div className="flex flex-col justify-end">
                    <div className="flex items-center gap-1">
                        <h1 className='text-indigo-950 dark:text-indigo-400 font-bold lg:text-xl'
                        >{activeUser?.name}</h1>
                        {/* Open user info */}
                        {
                            !editUser && 
                            <button className='p-1 text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white'>
                                <BiPencil className='w-5 h-5' onClick={() => setEditUser(true)} />
                            </button>
                        }
                    </div>
                    <div className="flex gap-1 items-center">
                        {/* status */}
                        <div className='h-3 w-3 border-2 bg-green-500 border-white rounded-full overflow-hidden'></div>
                        <span className='text-slate-700 dark:text-slate-300 text-sm'>En ligne</span>
                    </div>
                </div>
                {
                    editUser &&
                    <button className='p-2 ml-auto my-auto rounded-full text-sm text-white bg-emerald-500 hover:bg-emerald-600 disabled:cursor-not-allowed disabled:opacity-60'
                        onClick={handleUpdateUser}
                        disabled={isLoading}
                    >
                        {isLoading ? <AiOutlineLoading3Quarters className='w-6 h-6 animate-spin' /> : 'Enregistrer'}
                    </button>
                }

            </div>
            {
                editUser &&
                // Edit
                <div className='w-full p-2 bg-slate-100 dark:bg-gray-900 border-b-2 border-gray-200 dark:border-gray-800 divide-y dark:divide-gray-700'>
                    {
                        (image || imageTemp) &&
                        <div className='px-2 py-3 rounded-md w-full flex items-center gap-4'>
                            <label htmlFor="name" className='flex-shrink-0 h-4 w-4'>
                                <ImFilePicture className='w-4 h-4 lg:w-5 lg:h-5 text-black dark:text-white' />
                            </label>
                            <button type="button" id="name"
                                className='w-full outline-none text-black dark:text-white bg-transparent text-left active:scale-95 hover:!text-orange-600'
                                onClick={handleRemovePhoto}
                            >Supprimer la photo de profil</button>
                        </div>
                    }
                    <div className='px-2 py-3 rounded-md w-full flex items-center gap-4'>
                        <label htmlFor="name" className='flex-shrink-0 h-4 w-4'>
                            <BiUser className='w-4 h-4 lg:w-5 lg:h-5 text-black dark:text-white' />
                        </label>
                        <input type="text" id="name"
                            className='w-full outline-none text-black dark:text-white bg-transparent'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='px-2 py-3 rounded-md w-full flex items-center gap-4'>
                        <label htmlFor="email" className='flex-shrink-0 h-4 w-4'>
                            <MdAlternateEmail className='w-4 h-4 lg:w-5 lg:h-5 text-black dark:text-white' />
                        </label>
                        <input type="text" id="email"
                            className='w-full outline-none text-black dark:text-white bg-transparent'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div className='px-2 py-3 w-full flex items-center gap-4 relative cursor-pointer'
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        <label htmlFor="status" className='flex-shrink-0 h-5 w-5 flex cursor-pointer'>
                            <div className={`h-3 w-3 ${status.theme} rounded-full m-auto`} />
                        </label>
                        <div className="flex justify-between w-full">
                            <input type="text" id="name"
                                className='w-full cursor-pointer flex-grow outline-none text-black dark:text-white bg-transparent'
                                value={status.label}
                                readOnly
                            />
                            <button className=''>
                                {
                                    showDropdown ?
                                    <BiChevronUp className='h-6 w-6 text-gray-400 dark:text-gray-600'/>
                                    :
                                    <BiChevronDown className='h-6 w-6 text-gray-400 dark:text-gray-600'/>
                                }
                            </button>
                        </div>
                    </div>
                    {
                        showDropdown &&
                        <div className={`p-1 bg-transparent px-8 space-y-0`}>
                            {
                                STATUS
                                .filter(stat => stat.label !== status.label && stat.theme !== status.theme)
                                .map((status) => (
                                    <button type='button'
                                        key={status.value}
                                        onClick={() => {setStatus(status); setShowDropdown(false);}}
                                        className="flex w-full rounded-md gap-4 p-2 items-center justify-start hover:bg-indigo-100 dark:hover:bg-indigo-900"
                                    >
                                        <div className={`h-3 w-3 ${status.theme} rounded-full`} />
                                        <span className='text-sm text-black dark:text-white'>{status.label}</span>
                                    </button>
                                ))
                            }
                        </div>
                    }
                </div>
            }
            <div className="w-full space-y-0 p-2 divide-y dark:divide-gray-700">
                {/* <button className='px-2 py-3 rounded-md w-full flex items-center gap-4 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700'>
                    <PiPencil className='w-4 h-4 lg:w-5 lg:h-5' />
                    <span className='uppercase max-md:text-sm '>MODIFIER L'UTILISATEUR</span>
                </button> */}
                <button
                    className='px-2 py-3 rounded-md w-full flex items-center gap-4 text-red-800 dark:text-red-500 hover:bg-gray-200 dark:hover:bg-gray-700'
                    onClick={() => onLogout && onLogout()}
                >
                    <BiLogOut className='w-4 h-4 lg:w-5 lg:h-5' />
                    <span className='uppercase max-md:text-sm'>Se Deconnecter</span>
                </button>
            </div>
        </div>
    )
}

export default UserActiveModal