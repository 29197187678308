import React, { useEffect, useRef, useState } from 'react'
import { STATUS, getTag, profileColor } from '../utils/tools';
import { PiPencil } from 'react-icons/pi';
import { BiChevronDown, BiChevronUp, BiLogOut, BiPencil, BiUser } from 'react-icons/bi';
import { BsCameraFill, BsEmojiAngryFill } from 'react-icons/bs';
import { MdAlternateEmail } from 'react-icons/md';
import { useClickAway } from '@uidotdev/usehooks';
import { motion } from 'framer-motion';
import { SOCKET_SERVER_URL, USER_PIC_DEFAULT, session, updateUser } from '../utils/func';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { ImFilePicture } from 'react-icons/im';

const UserInfoModal = ({ user, onClose, theme, onSendMessage }) => {

    const [userTag, setUserTag] = useState('');

    const ref = useClickAway(() => {
        onClose && onClose();
    });

    const handleSendMessage = () => {
        console.log(user)
        if (!user) return;
        onSendMessage && onSendMessage(user._id);
        onClose && onClose();
    }


    useEffect(() => {
        setUserTag(getTag(user.name));
        console.log(user.name)
    }, [user]);
    
    return (
        <motion.div ref={ref}
            className={`w-full ${theme} !bg-gradient-to-b ring-0 ring-offset-0  border-0 rounded-lg max-w-md shadow flex m-auto flex-col overflow-hidden`}
            initial={{ scale: 0, opacity: 0}}
            animate={{scale: 1, opacity: 1}}
        >
            {/* Profile photo */}
            <fieldset className="flex flex-col items-center gap-1 border-b py-8 dark:border-gray-700 bg-gray-100 dark:bg-gray-800">
                <legend className="text-center flex pt-12  w-full bg-gradient-to-b from-transparent to-transparent">
                    <div className={`relative mx-auto flex-shrink-0 ${profileColor(userTag)} w-24 h-24 max-w-24 min-h-24 rounded-full flex items-center justify-center`}>
                        {
                            (user.pic && user.pic !== "") ?
                            <img src={USER_PIC_DEFAULT === user.pic ? user.pic : `${SOCKET_SERVER_URL}/users/${user.pic}`} alt="profil" referrerPolicy='no-referrer' className='user-profile rounded-full'/>
                            :
                            <span className="font-bold text-xl">{userTag}</span>
                        }
                    </div>
                </legend>
                <div className="flex flex-col gap-2 items-center justify-end">
                    <div className="flex items-center gap-1">
                        <h1 className='w-full text-center text-indigo-950 dark:text-indigo-400 font-bold lg:text-2xl'
                        >{user?.name}</h1>
                    </div>
                    <div>
                        <p className='text-black dark:text-white bg-transparent'>{user.email}</p>
                    </div>
                    <div className="flex gap-1 items-center">
                        {/* status */}
                        <div className='h-3 w-3 border-2 bg-green-500 border-white rounded-full overflow-hidden'></div>
                        <span className='text-slate-700 dark:text-slate-300 text-sm'>{STATUS[user.status].label}</span>
                    </div>
                </div>
                <div className="w-full flex mt-2">
                    <button className='m-auto rounded bg-emerald-500 hover:bg-emerald-600 px-3 py-2 text-white'
                        onClick={handleSendMessage}
                    >
                        Envoyer un message
                    </button>
                </div>
            </fieldset>
        </motion.div>
    )
}

export default UserInfoModal