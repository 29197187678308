import React, { useEffect, useState } from 'react';
import {session, getAllFile } from '../../utils/func';

const AllImage = ({showSidebar, id}) => {

  const [file, setFile] = useState([])
  // console.log("id == ", id);

  const activeUser = session.user();

  useEffect(()=>{
    // console.log("useEffect");
    getAllFile(activeUser.token, id).then(res=>{
      // console.log("res", res.data);
      if (res.status === 200) {
        setFile(res.data)
      }
    })
  })
  return (
    
    <div className={`p-4 fixed top-16 right-0 h-100 shadow-lg shadow-slate-300 bg-white w-96 h-full rounded-bl-lg	 ${showSidebar ? '' : 'hidden'}`}>
   
      <h1 className="text-2xl left-4 font-bold">Sidebar</h1>
      <ul>
        <li className="mb-2">Item 1</li>
        <li className="mb-2">Item 2</li>
        <li className="mb-2">Item 3</li>
        <li className="mb-2">Item 4</li>
      </ul>

  </div>
  );
};

export default AllImage;
