import React from 'react'
import { getTag, profileColor } from '../../utils/tools';

const Seen = ({message}) => {
    return (
                <div className={`relative flex-shrink-0 w-4 h-4 max-w-4 max-h-4 ${profileColor(getTag(message))} rounded-full flex items-center justify-center`}>
                    <span className="font-bold text-xxxs uppercase">
                        {getTag(message)}
                    </span>
                </div>
            
    )
}
export default Seen